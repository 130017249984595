.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  padding: 100px 0 50px;
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
}

.client {
  position: relative;
  @media screen and (max-width: 850px) {
    margin: 20px 0 30px;
  }
  img {
    border-radius: 100%;
    width: 200px;
    height: 200px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -40px;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: var(--fill-secondary);
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -40px;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: var(--fill-primary);
    z-index: -1;
  }
}

.textWrapper {
  text-align: center;
  width: 70%;

}

.title, .titleMobile {
  font-family: "BriceRegular";
  font-size: 40px;
  color: var(--fill-light-primary);
  margin-bottom: 10px;
  font-weight: bold;
}

.title {
  display: block;
  @media screen and (max-width: 850px) {
    display: none;
  }
}

.titleMobile {
  display: none;
  @media screen and (max-width: 850px) {
    display: block;
  }
}