.root {
  padding-top: 230px;
  display: flex;
  width: 80%;
  margin: 0 auto 100px;
  min-height: 65vh;
  @media screen and (max-width: 768px) {
    padding-top: 150px;
    flex-direction: column-reverse;
  }
}

.illustration {
  width: 40%;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.services {
  margin-left: 10%;
  border-top: 1px solid var(--fill-primary);
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.item {
  border-bottom: 1px solid var(--fill-primary);
  cursor: pointer;
}

.itemTitle {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}

.actionButton {
  color: var(--fill-primary);
  width: 30px;
  height: 30px;
  border: 0;
  font-size: 1.5em;
  position: relative;
  margin-right: 10px;
}

.actionButton span {
  position: absolute;
  transition: 300ms;
  background: var(--fill-primary);;
}

/* Create the "+" shape by positioning the spans absolutely */
.actionButton span:first-child {
  top: 22%;
  bottom: 22%;
  width: 1px;
  left: 37%;
}

.actionButton span:last-child {
  left: 7%;
  right: 28%;
  height: 1px;
  top: 48%;
}

/* Morph the shape when the .actionButton is hovered over */
.actionButton:hover span {
  transform: rotate(90deg);
}

.actionButton:hover span:last-child {
  left: 50%;
  right: 50%;
}

.minus span {
  transform: rotate(90deg);
}

.minus span:last-child {
  left: 50%;
  right: 50%;
}

.textWrapper {
  overflow: hidden;
  transition: max-height 0s ease-out;
  max-height: 0;
}

.textWrapper.visible {
  max-height: 300px; /* Or any large enough value */
  transition: max-height 1s ease-out;
}

.text {
  padding-bottom: 30px;
}