.footer {
  background-color: var(--fill-high-emphasis);
  padding: 30px 20px 20px;
  background-image: url("../../icons/footer.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: var(--fill-high-emphasis);
}

.menuItems {
  list-style-type: none;
  padding-left: 40px;
  @media screen and (max-width: 768px) {
    padding-left: 0;
    &:nth-child(2){
      padding-left: 20px;
    }
  }
}
.content{
  display: flex;
  width: 80%;
  margin: auto;
  @media screen and (max-width: 768px) {
   flex-direction: column;
  }
}
.instagram{
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}
.column{
  flex: 1;
}
nav {
  display: flex;
  text-align: left;
  line-height: 1.7;
  padding-bottom: 5px;
}


.goal {
  width: 30%;
  text-align: left;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }  @media screen and (max-width: 850px) {
    width: 65%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 10px;
  }

  .text {
    line-height: 1.7;
  }
}

.title{
  font-weight: bold;
  font-size: 20px;
}
.item, .activeItem {
  a {
    text-decoration: none;
    color: var(--fill-high-emphasis);
  }
}
.copy{
  text-align: center;
  font-size: 14px;
  padding-top: 30px;
}