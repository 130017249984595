:root {
  --multiplier: 1;
  --app-height: 100%;

  // Transitions
  --hover-transition: 200ms;
  --hover-opacity: 0.7;

  // Border radius
  --border-radius-1: 0.2rem;
  --border-radius-2: 0.4rem;
  --border-radius-3: 0.6rem;

  --link-color: #69cdff;
}

body {
  // Fonts
  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Poppins", sans-serif;

  // Header height
  --header-height: 3.6rem;

  --fill-high-emphasis: rgb(var(--fill-high-emphasis-code));
  --fill-high-emphasis-core: #ffffff;
  --fill-high-emphasis-code: 237, 236, 239;
  //--fill-high-emphasis-code: 247, 244, 255;


  --fill-dark-emphasis: rgb(var(--fill-dark-emphasis-code));
  --fill-dark-emphasis-core: #3d3d3d;
  --fill-dark-emphasis-code: 61, 61, 61;

  color: var(--fill-dark-emphasis);

  --fill-primary: rgb(var(--fill-primary-code));
  --fill-primary-core: #6E51D9;
  --fill-primary-code: 110, 81, 217;

  --fill-light-primary: rgb(var(--fill-light-primary-code));
  --fill-light-primary-core: #A08AE5;
  --fill-light-primary-code: 160, 138, 229;

  --fill-secondary: rgb(var(--fill-secondary-code));
  --fill-secondary-core: #B0E212;
  --fill-secondary-code: 176, 226, 18;

  --fill-light-secondary: rgb(var(--fill-light-secondary-code));
  --fill-light-secondary-core: #D2FF58;
  --fill-light-secondary-code: 210, 255, 88;
}
