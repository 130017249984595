.root{
  padding-top: 230px;
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto 100px;
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    padding-top: 150px;
  }
}

.illustration{
  width: 40%;
  @media screen and (max-width: 850px) {
    width: 100%;
    padding-bottom: 30px;
  }
}
.descContainer{
  padding-left: 10%;
  @media screen and (max-width: 850px) {
  padding-left: 0;
  }
}
.tagline{
  font-size: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sparkle{
  font-size: 22px;
  padding: 0 3px;
  color: var(--fill-light-primary);
  line-height: 1.6;
}
.title{
  font-family: "BriceRegular";
  font-size: 45px;
  color: var(--fill-light-primary);
  margin-bottom: 40px;
  font-weight: bold;
  width: 80%;
  @media screen and (max-width: 850px) {
    width: 100%;
  }
 span{
   position: relative;
 }
  svg{
    z-index: -1;
    position: absolute;
    display: block;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    stroke: var(--fill-secondary);
    stroke-width: 0.15em;
    stroke-width: max(1px, 0.15em);
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    opacity: 1;
    animation: dash 5s forwards;
    stroke-dasharray: 565;
    stroke-dashoffset: 565;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.text{
  font-size: 18px;
  margin-bottom: 30px;
}

