@import "./variables";
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "BriceRegular";
  src: url("fonts/Brice-Regular.woff") format("woff");
}

body {
  margin: 0;
  font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--fill-high-emphasis);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
