.root{
  background-image: url("../../../../icons/sectionTwo.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px 0;
}
.container{
  width: 80%;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  background-color: var(--fill-high-emphasis-core);
  @media screen and (max-width: 850px) {
    flex-direction: column-reverse;
    padding: 15px 40px;
  }
}
.desc{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.title{
  font-family: "BriceRegular";
  font-size: 40px;
  color: var(--fill-light-primary);
  margin-bottom: 10px;
  font-weight: bold;
  width: 60%;
  text-align: center;
  @media screen and (max-width: 850px) {
    width: 100%;
    font-size: 35px;
  }
}
.text{
  margin-bottom: 30px;
}
.illustration{
  width: 34%;
  max-height: 600px;
  @media screen and (max-width: 850px) {
  width: 100%;
  }
}