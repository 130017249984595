.button{
  padding: 15px 40px;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}

//colors

.primary {
  color: var(--fill-light-primary);
  border: 2px solid  var(--fill-light-primary);
  background-color: transparent;

  &:disabled {
    color: grey;
  }
  &:hover:not(:disabled) {
    background-color: var(--fill-light-primary);
    border: 2px solid  var(--fill-light-primary);
    color: var(--fill-high-emphasis);
  }

  &:focus:not(:disabled) {
  }

  &:active:not(:disabled) {
  }
}