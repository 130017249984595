.root {
  display: flex;
  width: 100%;
  padding: 20px 40px 5px;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: background-color 0.3s;
  min-height: 160px;
  background-color: var(--fill-high-emphasis);

  a {
    color: var(--fill-primary);
    text-decoration: none;
  }

  @media screen and (max-width: 850px) {
    background-color: var(--fill-high-emphasis);
  }

  @media screen and (max-width: 768px) {
    min-height: 120px;
  }
}

.webHeader{
  display: flex;
  width: 100%;
  transition: background-color 0.3s;
  min-height: 160px;
  background-color: var(--fill-high-emphasis);

  a {
    color: var(--fill-primary);
    text-decoration: none;
  }

  @media screen and (max-width: 850px) {
    background-color: var(--fill-high-emphasis);
  }
}
.mobileHeader{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .content {
    display: none;
    position: absolute;
    height: 100vh;
    background: rgba(255,255,255,0.5);
    backdrop-filter: blur(15px);
    flex-direction: column;
    top: 0;
    left: 0;
    .menuItems{
      flex-direction: column;
      justify-content: center;
    }
    .activeItem, .item{
      height: auto;
    }
  }
  .openContent{
    display: block;
  }
}


.socials {
  padding-right: 15px;
  padding-top: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  @media screen and (max-width: 850px) {
    padding-right: 0;
  }

  a {
    padding-right: 5px;
  }
}

.icon {
  display: block;
  width: 35px;
  height: 35px;

  &:first-child {
    margin-right: 7px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.centerPart {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  color: var(--fill-high-emphasis);
  text-decoration: none;
  font-size: 36px;
  width: 200px;
  height: 100px;
  @media screen and (max-width: 850px) {
    width: 200px;
    height: 100px;
  }
  @media screen and (max-width: 375px) {
    width: 150px;
    height: 60px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.content {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 850px) {
    height: 60px;
  }
}

.menuItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  height: 100%;
  text-transform: uppercase;
}

.contact {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  font-weight: 600;
}

.item, .activeItem {
  padding: 0 5px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  @media screen and (max-width: 850px) {
    font-size: 16px;
  }

  .subsections {
    opacity: 0;
    position: absolute;
    top: 70px;
    background-color: var(--fill-primary);
    list-style-type: none;
    padding: 15px;
    pointer-events: none;

    a {
      white-space: nowrap;
    }
  }

  .subsection {
    padding-bottom: 12px;
  }

  &:hover {
    color: var(--fill-secondary);

    .subsections {
      opacity: 1;
      pointer-events: auto;
    }
  }

  a {
    color: var(--fill-primary);
    text-decoration: none;
    padding: 10px 20px;

    &:hover {
      background-color: rgb(210 232 155);
      padding: 10px 20px;
      border-radius: 100px;

    }
  }
}

.activeItem {
  a {
    background-color: rgb(210 232 155);
    padding: 10px 20px;
    border-radius: 100px;
  }
}

.callButton {
  padding: 12px 20px;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  color: var(--fill-light-primary);
  background-color: transparent;
  &:hover:not(:disabled) {
    background-color: var(--fill-light-primary);
    border: 2px solid  var(--fill-light-primary);
    color: var(--fill-high-emphasis);
    box-shadow: none;
    transform: translatey(4px) !important;
  }
  box-shadow: 5px 5px var(--fill-light-primary);
  border: 1px solid var(--fill-light-primary) !important;
  transition: all ease-in .2s !important;
}

.burgerMenu {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 10px 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 2;
  zoom: 0.8;
  @media screen and (max-width: 375px) {
    zoom: 0.7
  }

}

.burgerMenu span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: var(--fill-primary);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.burgerMenu span:nth-child(1) {
  top: 0px;
}

.burgerMenu span:nth-child(2),.burgerMenu span:nth-child(3) {
  top: 18px;
}

.burgerMenu span:nth-child(4) {
  top: 36px;
}

.burgerMenu.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.burgerMenu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burgerMenu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burgerMenu.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
